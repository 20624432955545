import React, { Component } from 'react'
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom'
import AuthContextProvider from './contexts/AuthContext'
import './scss/style.scss'
import PrivateRoute from './privateRoutes'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Attendances = React.lazy(() => import('./views/app/attendances/Attendances'))


// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Fop = React.lazy(() => import('./views/pages/fop/Fop'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      /**
       * BrowserRouter syncs the UI with the current URL in your browser, This is done by the means of HTML - 5 History API.
       * On the other hand, HashRouter uses the Hash part of your URL to sync.
       * The main use case scenario for choosing "hash router" over "browser router" is on production environment.
       */
      < HashRouter >
        <AuthContextProvider>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route exact path="/fop-2024" name="Festival of Praise" render={(props) => <Fop {...props} />} />
              <PrivateRoute path="/" name="Home" component={DefaultLayout} />


            </Switch>
          </React.Suspense>
        </AuthContextProvider>
      </HashRouter >
    )
  }
}

export default App
