export const AuthReducer = (state, action) => {
    switch (action.type) {
        case "SAVE_USER":
            return {
                ...state,
                user: action.payload
            };
        case "SAVE_TOKEN":
            return {
                ...state,
                token: action.payload,
                isAuthenticated: true
            }
        default:
            return state;
    }
};