import React, { createContext, useReducer } from 'react';

import { AuthReducer } from '../reducers/AuthReducer';

export const AuthContext = createContext();

export default function AuthContextProvider(props) {
    let token = localStorage.getItem('token');

    const initialState = {
        user: {},
        token: token?token:'',
        isAuthenticated: false
    };

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    // Load User
    const saveUserData = async (data) => {
            dispatch({ type: "SAVE_USER", payload: data  })
    };

    const saveToken = async (token) => {
        dispatch({ type: "SAVE_TOKEN", payload: token  })
    };

    return (
        <AuthContext.Provider
            value={{
                user: state.user,
                token: state.token,
                saveUserData,
                saveToken,
                isAuthenticated: false
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}