import React, { useEffect, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { token } = useContext(AuthContext);
    // let token = localStorage.getItem('token');

    // useEffect(() => {
    //     if (!isAuthenticated || !token) {
    //         localStorage.setItem('token', '');
    //         window.location.reload();
    //     }
    // }, [isAuthenticated, token]);
// console.log(isAuthenticated, token)
    return (
        <Route
            {...rest}
            render={props => {
                if (token) {
                    return (
                        <main id="private-route-layout">
                            <Component {...props} />
                        </main>
                    );
                } else {
                    //enable this line below to make route protected
                    return <Redirect to="/login" />;
                }
            }}
        />
    );
};

export default PrivateRoute;
